export const CaseStudies = [
  {
    id: 1,
    caseTopic: "Cybersecurity Strategies",
    lesson: "Protecting businesses in the digital age",
    futureSkills:
      "Threat intelligence, security architecture, incident response",
    characters: "3",
    roles: [
      {
        role1: "Chief Information Security Officer",
        role2: "IT Security Manager",
        role3: "Compliance Officer",
      },
    ],
    roleOfMainCharacter: "Chief Information Security Officer",
    challenge:
      "Implementing robust security measures while maintaining operational efficiency",
    videoLink: "https://www.example.com/cybersecurity-case.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/cybersecurity-concept-with-lock-circuit-board_23-2149661647.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1800",
    rating: "4.8",
    caseAuthorDesignation: "Cybersecurity Strategy Director",
    subjectCategory: "Information Technology",
    subjectSubCategory: "Cybersecurity",
    publicationDate: "15-Jan-2024",
    caseLocation: "United States",
    language: "English",
    tags: ["Cybersecurity", "Data Protection", "Risk Management"],
    extract:
      "A multinational corporation faces sophisticated cyber threats targeting their customer data. The case explores their comprehensive defense strategy, incident response planning, and compliance with global regulations.",
    learningArea: "Cybersecurity Management",
  },
  {
    id: 2,
    caseTopic: "Cloud Computing Impact",
    lesson: "Leveraging cloud solutions for business scalability",
    futureSkills:
      "Cloud architecture, cost optimization, hybrid cloud management",
    characters: "3",
    roles: [
      {
        role1: "Cloud Strategy Director",
        role2: "Infrastructure Manager",
        role3: "CFO",
      },
    ],
    roleOfMainCharacter: "Cloud Strategy Director",
    challenge:
      "Migrating legacy systems to cloud infrastructure while minimizing disruption",
    videoLink: "https://www.example.com/cloud-case.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/cloud-computing-technology-with-3d-render-cloud-storage_107791-16262.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1650",
    rating: "4.7",
    caseAuthorDesignation: "Cloud Transformation Consultant",
    subjectCategory: "Information Technology",
    subjectSubCategory: "Cloud Computing",
    publicationDate: "10-Mar-2024",
    caseLocation: "Germany",
    language: "English",
    tags: ["Cloud Migration", "Cost Optimization", "Remote Work"],
    extract:
      "A mid-sized financial services company transforms its operations by moving to a hybrid cloud model, resulting in 40% cost reduction and enhanced ability to support remote work during global disruptions.",
    learningArea: "Cloud Strategy",
  },
  {
    id: 3,
    caseTopic: "AI in Business Decision-Making",
    lesson: "Harnessing AI-driven insights for strategic advantage",
    futureSkills:
      "AI implementation, predictive analytics, data-driven decision making",
    characters: "3",
    roles: [
      {
        role1: "Chief Data Officer",
        role2: "AI Solutions Architect",
        role3: "Business Unit Head",
      },
    ],
    roleOfMainCharacter: "Chief Data Officer",
    challenge: "Integrating AI solutions into existing business processes",
    videoLink: "https://www.example.com/ai-case.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/ai-technology-microchip-background-digital-transformation-concept_53876-124669.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "2100",
    rating: "4.9",
    caseAuthorDesignation: "AI Strategy Executive",
    subjectCategory: "Information Technology",
    subjectSubCategory: "Artificial Intelligence",
    publicationDate: "05-Jun-2024",
    caseLocation: "Singapore",
    language: "English",
    tags: ["AI Implementation", "Predictive Analytics", "Process Optimization"],
    extract:
      "A retail giant implements machine learning algorithms to optimize inventory management and personalize customer experiences, resulting in 25% reduction in stockouts and 18% increase in customer satisfaction.",
    learningArea: "AI Business Applications",
  },
  {
    id: 4,
    caseTopic: "IT in Digital Transformation",
    lesson: "Modernizing legacy systems for competitive advantage",
    futureSkills:
      "Digital roadmap development, change management, technology integration",
    characters: "4",
    roles: [
      {
        role1: "Chief Digital Officer",
        role2: "IT Director",
        role3: "Change Management Lead",
        role4: "Business Process Analyst",
      },
    ],
    roleOfMainCharacter: "Chief Digital Officer",
    challenge: "Overcoming organizational resistance to technological change",
    videoLink: "https://www.example.com/digital-transformation.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-discussing-charts-graphs-showing-results-their-successful-teamwork_1150-5183.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1950",
    rating: "4.8",
    caseAuthorDesignation: "Digital Transformation Executive",
    subjectCategory: "Information Technology",
    subjectSubCategory: "Digital Transformation",
    publicationDate: "20-Sep-2024",
    caseLocation: "United Kingdom",
    language: "English",
    tags: ["Legacy Modernization", "Digital Strategy", "Change Management"],
    extract:
      "A 75-year-old manufacturing company undertakes a comprehensive digital transformation initiative, replacing paper-based processes with integrated digital systems while navigating significant cultural challenges.",
    learningArea: "Digital Transformation Strategy",
  },
  {
    id: 5,
    caseTopic: "IT Governance and Compliance",
    lesson: "Navigating complex data privacy regulations",
    futureSkills: "Regulatory compliance, data governance, privacy by design",
    characters: "3",
    roles: [
      {
        role1: "Chief Compliance Officer",
        role2: "Data Protection Officer",
        role3: "IT Governance Manager",
      },
    ],
    roleOfMainCharacter: "Chief Compliance Officer",
    challenge:
      "Ensuring global compliance while maintaining operational efficiency",
    videoLink: "https://www.example.com/compliance-case.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/gdpr-concept-with-padlock-circuit-board_23-2148872592.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1750",
    rating: "4.6",
    caseAuthorDesignation: "Data Governance Specialist",
    subjectCategory: "Information Technology",
    subjectSubCategory: "IT Governance",
    publicationDate: "12-Feb-2025",
    caseLocation: "European Union",
    language: "English",
    tags: ["GDPR", "Data Privacy", "Regulatory Compliance"],
    extract:
      "A multinational technology company develops a comprehensive framework to address varying data privacy regulations across different regions, including GDPR in Europe, CCPA in California, and industry-specific requirements.",
    learningArea: "IT Compliance Strategy",
  },
  {
    id: 6,
    caseTopic: "Social Selling Strategies",
    lesson: "Leveraging social platforms for B2B sales success",
    futureSkills:
      "Social media engagement, relationship building, digital networking",
    characters: "3",
    roles: [
      {
        role1: "Social Selling Director",
        role2: "B2B Sales Manager",
        role3: "Content Marketing Specialist",
      },
    ],
    roleOfMainCharacter: "Social Selling Director",
    challenge:
      "Converting social connections into qualified sales opportunities",
    videoLink: "https://www.example.com/social-selling.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-discussing-social-media-strategy_53876-94856.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1450",
    rating: "4.7",
    caseAuthorDesignation: "B2B Social Selling Expert",
    subjectCategory: "Sales",
    subjectSubCategory: "Social Selling",
    publicationDate: "25-Mar-2024",
    caseLocation: "Canada",
    language: "English",
    tags: ["LinkedIn", "B2B Sales", "Social Media", "Relationship Building"],
    extract:
      "A B2B software company transforms its sales approach by implementing a comprehensive LinkedIn strategy, resulting in 35% more qualified leads and a 28% increase in sales pipeline value.",
    learningArea: "Social Selling",
  },
  {
    id: 7,
    caseTopic: "Sales Enablement Excellence",
    lesson: "Empowering sales teams with effective tools and training",
    futureSkills:
      "Sales technology implementation, content strategy, performance analytics",
    characters: "4",
    roles: [
      {
        role1: "Sales Enablement Director",
        role2: "Sales Operations Manager",
        role3: "Learning & Development Lead",
        role4: "CRM Administrator",
      },
    ],
    roleOfMainCharacter: "Sales Enablement Director",
    challenge: "Streamlining the sales tech stack while improving adoption",
    videoLink: "https://www.example.com/sales-enablement.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-training-concept-with-wooden-blocks-papers-pen_176474-9553.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1700",
    rating: "4.8",
    caseAuthorDesignation: "Sales Enablement Strategist",
    subjectCategory: "Sales",
    subjectSubCategory: "Sales Enablement",
    publicationDate: "18-May-2024",
    caseLocation: "Australia",
    language: "English",
    tags: ["Sales Technology", "Training", "Content Strategy", "CRM"],
    extract:
      "A global manufacturing company revamps its sales enablement program, implementing a unified technology platform and just-in-time training approach that reduces onboarding time by 40% and increases quota attainment.",
    learningArea: "Sales Enablement",
  },
  {
    id: 8,
    caseTopic: "AI-Powered Sales Prediction",
    lesson:
      "Using machine learning for lead scoring and conversion optimization",
    futureSkills:
      "Predictive analytics, AI implementation, data-driven selling",
    characters: "3",
    roles: [
      {
        role1: "Sales Analytics Director",
        role2: "AI Implementation Specialist",
        role3: "Enterprise Sales Manager",
      },
    ],
    roleOfMainCharacter: "Sales Analytics Director",
    challenge:
      "Building accurate predictive models with limited historical data",
    videoLink: "https://www.example.com/ai-sales.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-discussing-charts-graphs-showing-results-their-successful-teamwork_1150-5183.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1950",
    rating: "4.9",
    caseAuthorDesignation: "AI Sales Technology Executive",
    subjectCategory: "Sales",
    subjectSubCategory: "Sales Analytics",
    publicationDate: "07-Aug-2024",
    caseLocation: "United States",
    language: "English",
    tags: ["AI", "Predictive Analytics", "Lead Scoring", "Sales Forecasting"],
    extract:
      "A SaaS company implements an AI-driven lead scoring system that analyzes thousands of data points to predict conversion likelihood, resulting in 45% higher conversion rates and more efficient resource allocation.",
    learningArea: "Predictive Sales",
  },
  {
    id: 9,
    caseTopic: "Customer-Centric Selling",
    lesson: "Aligning sales approaches with modern buyer behavior",
    futureSkills:
      "Consultative selling, buyer journey mapping, personalization",
    characters: "3",
    roles: [
      {
        role1: "Customer Experience Director",
        role2: "Sales Methodology Coach",
        role3: "Voice of Customer Analyst",
      },
    ],
    roleOfMainCharacter: "Customer Experience Director",
    challenge: "Transitioning from product-focused to customer-centric selling",
    videoLink: "https://www.example.com/customer-centric.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/close-up-people-working-office_23-2149346465.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1550",
    rating: "4.7",
    caseAuthorDesignation: "Customer-Centric Sales Strategist",
    subjectCategory: "Sales",
    subjectSubCategory: "Sales Methodology",
    publicationDate: "14-Oct-2024",
    caseLocation: "United Kingdom",
    language: "English",
    tags: [
      "Customer Experience",
      "Consultative Selling",
      "Buyer Journey",
      "Personalization",
    ],
    extract:
      "A technology hardware provider transforms its sales organization by implementing a consultative selling approach based on deep customer insights, resulting in longer but more successful sales cycles and higher customer retention.",
    learningArea: "Customer-Centric Sales",
  },
  {
    id: 10,
    caseTopic: "Strategic Pricing Optimization",
    lesson: "Developing pricing strategies for competitive advantage",
    futureSkills: "Value-based pricing, competitive analysis, price modeling",
    characters: "3",
    roles: [
      {
        role1: "Pricing Strategy Director",
        role2: "Sales Operations Manager",
        role3: "Financial Analyst",
      },
    ],
    roleOfMainCharacter: "Pricing Strategy Director",
    challenge: "Implementing value-based pricing in a price-sensitive market",
    videoLink: "https://www.example.com/pricing-strategy.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-discussing-charts-graphs-showing-results-their-successful-teamwork_1150-5183.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1800",
    rating: "4.6",
    caseAuthorDesignation: "Strategic Pricing Consultant",
    subjectCategory: "Sales",
    subjectSubCategory: "Pricing Strategy",
    publicationDate: "22-Jan-2025",
    caseLocation: "Germany",
    language: "English",
    tags: [
      "Value-Based Pricing",
      "Dynamic Pricing",
      "Competitive Analysis",
      "Revenue Optimization",
    ],
    extract:
      "A B2B services company transitions from cost-plus to value-based pricing, developing a sophisticated pricing model that segments customers based on perceived value and willingness to pay, resulting in 18% margin improvement.",
    learningArea: "Pricing Strategy",
  },
  {
    id: 11,
    caseTopic: "Influencer Marketing ROI Analysis",
    lesson: "Evaluating and optimizing influencer marketing investments",
    futureSkills:
      "Influencer analytics, ROI measurement, campaign optimization",
    characters: "3",
    roles: [
      {
        role1: "Marketing Director",
        role2: "Social Media Manager",
        role3: "Analytics Specialist",
      },
    ],
    roleOfMainCharacter: "Marketing Director",
    challenge: "Determining the true ROI of influencer partnerships",
    videoLink: "https://www.example.com/video1.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/front-view-pretty-woman-showing-diagrams-working-office_140725-109240.jpg?t=st=1740137553~exp=1740141153~hmac=a5d14f909bf99c707d84463c8309f5e7eb10c77fd4928995fa7c41428da5846b&w=1380",
    price: "1400",
    rating: "4.5",
    caseAuthorDesignation: "Digital Marketing Strategist",
    subjectCategory: "Marketing",
    subjectSubCategory: "Digital Marketing",
    publicationDate: "15-Jan-24",
    caseLocation: "United States",
    language: "English",
    tags: ["Influencer Marketing", "ROI Analysis", "Social Media"],
    extract:
      "A global beauty brand struggles to measure and optimize their influencer marketing investments. The case explores methods for tracking ROI, selecting effective influencers, and maximizing campaign impact.",
    learningArea: "Marketing Analytics",
  },
  {
    id: 12,
    caseTopic: "AI-Driven Marketing Personalization",
    lesson: "Implementing AI for personalized customer experiences",
    futureSkills: "AI implementation, customer segmentation, data analysis",
    characters: "4",
    roles: [
      {
        role1: "Marketing Technology Lead",
        role2: "Data Scientist",
        role3: "Customer Experience Manager",
        role4: "Digital Marketing Specialist",
      },
    ],
    roleOfMainCharacter: "Marketing Technology Lead",
    challenge: "Scaling personalization while maintaining privacy",
    videoLink: "https://www.example.com/video2.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/standard-quality-control-concept-m_23-2150041855.jpg?uid=R180763559&semt=ais_hybrid",
    price: "1600",
    rating: "4.7",
    caseAuthorDesignation: "MarTech Innovation Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Marketing Technology",
    publicationDate: "20-Feb-24",
    caseLocation: "Singapore",
    language: "English",
    tags: ["AI Marketing", "Personalization", "MarTech"],
    extract:
      "An e-commerce company implements AI-driven personalization to improve customer engagement. The case examines the technical implementation, privacy considerations, and impact on customer satisfaction.",
    learningArea: "Marketing Technology",
  },
  {
    id: 13,
    caseTopic: "Sustainable Marketing Strategy",
    lesson: "Building brand value through sustainable practices",
    futureSkills:
      "Sustainability marketing, ethical branding, impact measurement",
    characters: "3",
    roles: [
      {
        role1: "Brand Manager",
        role2: "Sustainability Officer",
        role3: "Marketing Communications Manager",
      },
    ],
    roleOfMainCharacter: "Brand Manager",
    challenge: "Balancing profitability with sustainability goals",
    videoLink: "https://www.example.com/video3.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/creative-monitor-tech-digitally-generated-desk_1134-719.jpg?t=st=1740137388~exp=1740140988~hmac=20d42c592163892f1fe655c91dcaeb10c22663294e9ef270fce907fe0e393998&w=1380",
    price: "1300",
    rating: "4.6",
    caseAuthorDesignation: "Sustainable Brand Strategist",
    subjectCategory: "Marketing",
    subjectSubCategory: "Brand Strategy",
    publicationDate: "10-Mar-24",
    caseLocation: "Netherlands",
    language: "English",
    tags: ["Sustainability", "Brand Strategy", "Ethical Marketing"],
    extract:
      "A consumer goods company transforms its marketing strategy to focus on sustainability. The case explores how ethical marketing practices impact brand perception and business growth.",
    learningArea: "Sustainable Marketing",
  },
  {
    id: 14,
    caseTopic: "Short-Form Video Marketing Success",
    lesson: "Maximizing impact through short-form video content",
    futureSkills:
      "Video content strategy, platform optimization, engagement metrics",
    characters: "3",
    roles: [
      {
        role1: "Content Strategy Director",
        role2: "Video Producer",
        role3: "Social Media Specialist",
      },
    ],
    roleOfMainCharacter: "Content Strategy Director",
    challenge: "Creating viral short-form video content",
    videoLink: "https://www.example.com/video4.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/young-hispanic-man-holding-video-film-clapboard-megaphone-smiling-with-happy-cool-smile-face-showing-teeth_839833-25247.jpg?t=st=1740137735~exp=1740141335~hmac=507579553388d9c7fab17673b9670cdd16d103d412e67f5636baee37608e32b3&w=1380",
    price: "1200",
    rating: "4.8",
    caseAuthorDesignation: "Digital Content Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Content Marketing",
    publicationDate: "05-Apr-24",
    caseLocation: "South Korea",
    language: "English",
    tags: ["Video Marketing", "Social Media", "Content Strategy"],
    extract:
      "A fashion retailer leverages short-form video platforms to increase brand awareness. The case analyzes successful content strategies across TikTok, Instagram Reels, and YouTube Shorts.",
    learningArea: "Digital Content Strategy",
  },
  {
    id: 15,
    caseTopic: "Community-Driven Marketing",
    lesson: "Building and leveraging brand communities",
    futureSkills:
      "Community management, advocacy programs, engagement strategies",
    characters: "3",
    roles: [
      {
        role1: "Community Manager",
        role2: "Brand Engagement Specialist",
        role3: "Social Media Manager",
      },
    ],
    roleOfMainCharacter: "Community Manager",
    challenge: "Creating an engaged brand community",
    videoLink: "https://www.example.com/video5.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/social-media-network_74855-4575.jpg?t=st=1740137931~exp=1740141531~hmac=6dd51248d42519773a376d6ea905ab473dc6c75bcc3912665ea9038a7c0e5d0e&w=1800",
    price: "1100",
    rating: "4.5",
    caseAuthorDesignation: "Community Marketing Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Community Marketing",
    publicationDate: "15-May-24",
    caseLocation: "Canada",
    language: "English",
    tags: ["Community Marketing", "Brand Advocacy", "Social Media"],
    extract:
      "A tech startup builds a strong community around its product through user-generated content and advocacy programs. The case examines strategies for community engagement and growth.",
    learningArea: "Community Marketing",
  },
  {
    id: 16,
    caseTopic: "Omnichannel Marketing Strategy",
    lesson: "Creating seamless customer experiences across channels",
    futureSkills: "Omnichannel strategy, customer journey mapping, integration",
    characters: "4",
    roles: [
      {
        role1: "Marketing Director",
        role2: "Digital Strategist",
        role3: "Customer Experience Manager",
        role4: "Analytics Specialist",
      },
    ],
    roleOfMainCharacter: "Marketing Director",
    challenge: "Integrating online and offline channels effectively",
    videoLink: "https://www.example.com/video1.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/online-shopping-banner-mobile-app-templates-concept-flat-design_1150-34863.jpg?t=st=1740138589~exp=1740142189~hmac=da48130590aac61d8c612aedd94064ef7ff025801c4d99cedbbbe0dc58c62353&w=900",
    price: "1700",
    rating: "4.6",
    caseAuthorDesignation: "Omnichannel Marketing Strategist",
    subjectCategory: "Marketing",
    subjectSubCategory: "Omnichannel Marketing",
    publicationDate: "15-Jan-2024",
    caseLocation: "United States",
    language: "English",
    tags: ["Omnichannel", "Customer Experience", "Digital Integration"],
    extract:
      "A retail company implements an omnichannel strategy to create seamless customer experiences across physical stores and digital platforms. The case explores integration challenges and success metrics.",
    learningArea: "Marketing Integration",
  },
  {
    id: 17,
    caseTopic: "Data-Driven Marketing Analytics",
    lesson: "Leveraging data analytics for marketing optimization",
    futureSkills: "Data analytics, AI implementation, predictive modeling",
    characters: "3",
    roles: [
      {
        role1: "Marketing Analytics Manager",
        role2: "Data Scientist",
        role3: "Campaign Manager",
      },
    ],
    roleOfMainCharacter: "Marketing Analytics Manager",
    challenge: "Implementing data-driven decision making",
    videoLink: "https://www.example.com/video2.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/linear-flat-visitor-analytics-website-hero-image-illustration-seo-smm-online-marketing-concept-laptop-tablet-with-report-data-screen_126523-2649.jpg?t=st=1740138428~exp=1740142028~hmac=b2e986689654ec68786402ece45d220646a31219b0a8cdad1bc76eceab4638d8&w=1380",
    price: "1900",
    rating: "4.7",
    caseAuthorDesignation: "Marketing Analytics Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Marketing Analytics",
    publicationDate: "01-Apr-2024",
    caseLocation: "Singapore",
    language: "English",
    tags: ["Data Analytics", "AI Marketing", "Predictive Analytics"],
    extract:
      "A global company transforms its marketing approach through advanced analytics and AI. The case examines the implementation of data-driven strategies and their impact on marketing ROI.",
    learningArea: "Marketing Analytics",
  },
  {
    id: 18,
    caseTopic: "Brand Positioning Strategy",
    lesson: "Differentiating brands in saturated markets",
    futureSkills: "Brand strategy, market analysis, positioning",
    characters: "3",
    roles: [
      {
        role1: "Brand Manager",
        role2: "Market Research Analyst",
        role3: "Creative Director",
      },
    ],
    roleOfMainCharacter: "Brand Manager",
    challenge: "Creating unique brand positioning",
    videoLink: "https://www.example.com/video3.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/illustration-business-branding_53876-20535.jpg?t=st=1740138751~exp=1740142351~hmac=5221a443308036476f1f5c5dbb9c19303c2135582dca0e6290f317e009164008&w=1480",
    price: "1600",
    rating: "4.5",
    caseAuthorDesignation: "Brand Strategy Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Brand Strategy",
    publicationDate: "15-Jul-2024",
    caseLocation: "United Kingdom",
    language: "English",
    tags: ["Brand Strategy", "Market Positioning", "Competitive Analysis"],
    extract:
      "A consumer brand develops a unique positioning strategy in a highly competitive market. The case explores differentiation techniques and their impact on market share.",
    learningArea: "Brand Management",
  },
  {
    id: 19,
    caseTopic: "Customer Retention Strategy",
    lesson: "Building long-term customer relationships",
    futureSkills:
      "Customer loyalty, retention marketing, lifetime value analysis",
    characters: "3",
    roles: [
      {
        role1: "Customer Success Manager",
        role2: "Loyalty Program Manager",
        role3: "Marketing Specialist",
      },
    ],
    roleOfMainCharacter: "Customer Success Manager",
    challenge: "Improving customer retention rates",
    videoLink: "https://www.example.com/video4.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/customer-loyalty-concept-marketing-program-development-client-retention-idea-communication-relationship-with-customers-flat-vector-illustration_613284-2323.jpg?t=st=1740138789~exp=1740142389~hmac=808f0f1fc61edfd7dbe9c08a86cbc718616ad25988e24764ff95c640da62a4c8&w=1380",
    price: "1500",
    rating: "4.4",
    caseAuthorDesignation: "Customer Success Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Customer Retention",
    publicationDate: "01-Oct-2024",
    caseLocation: "Australia",
    language: "English",
    tags: ["Customer Retention", "Loyalty Programs", "Customer Success"],
    extract:
      "A subscription-based company implements innovative retention strategies to increase customer lifetime value. The case examines loyalty programs and engagement tactics.",
    learningArea: "Customer Retention",
  },
  {
    id: 20,
    caseTopic: "Post-Cookie Marketing Adaptation",
    lesson: "Navigating digital marketing in a privacy-first era",
    futureSkills: "Privacy-compliant marketing, first-party data strategy",
    characters: "3",
    roles: [
      {
        role1: "Digital Marketing Manager",
        role2: "Privacy Officer",
        role3: "Data Strategy Specialist",
      },
    ],
    roleOfMainCharacter: "Digital Marketing Manager",
    challenge: "Adapting to cookie-less marketing",
    videoLink: "https://www.example.com/video5.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/marketing-specialists-computer-with-megaphone-social-media-icons-social-media-marketing-social-networking-internet-marketing-concept-bright-vibrant-violet-isolated-illustration_335657-247.jpg?t=st=1740138659~exp=1740142259~hmac=0488ca4af8e452dccc7be7c46edc5399c83df57324b12c089352be6f9129c38c&w=1380",
    price: "1800",
    rating: "4.8",
    caseAuthorDesignation: "Digital Privacy Marketing Strategist",
    subjectCategory: "Marketing",
    subjectSubCategory: "Digital Privacy",
    publicationDate: "15-Jan-2025",
    caseLocation: "Germany",
    language: "English",
    tags: ["Privacy Marketing", "First-Party Data", "Digital Strategy"],
    extract:
      "A digital advertising company develops new strategies for the post-cookie era. The case explores privacy-compliant marketing approaches and first-party data utilization.",
    learningArea: "Privacy-First Marketing",
  },
  {
    id: 21,
    caseTopic: "Rebranding Strategy Analysis",
    lesson: "Understanding successful and failed rebranding initiatives",
    futureSkills: "Brand strategy, market research, consumer psychology",
    characters: "3",
    roles: [
      {
        role1: "Brand Manager",
        role2: "Marketing Director",
        role3: "Research Analyst",
      },
    ],
    roleOfMainCharacter: "Brand Manager",
    challenge: "Executing a successful brand transformation",
    videoLink: "https://www.example.com/video1.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/analytics-comparison-information-networking-concept_53876-15846.jpg?uid=R180763559&semt=ais_hybrid",
    price: "1800",
    rating: "4.7",
    caseAuthorDesignation: "Brand Strategy Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Brand Management",
    publicationDate: "20-Jan-2024",
    caseLocation: "United States",
    language: "English",
    tags: ["Rebranding", "Brand Strategy", "Market Research"],
    extract:
      "A global company undergoes a major rebranding initiative. The case examines successful and failed rebranding examples, providing insights into effective brand transformation strategies.",
    learningArea: "Brand Strategy",
  },
  {
    id: 22,
    caseTopic: "Digital Brand Loyalty",
    lesson: "Building and maintaining brand loyalty in the digital era",
    futureSkills: "Digital engagement, community building, loyalty programs",
    characters: "3",
    roles: [
      {
        role1: "Digital Brand Manager",
        role2: "Community Manager",
        role3: "Customer Experience Lead",
      },
    ],
    roleOfMainCharacter: "Digital Brand Manager",
    challenge: "Creating lasting digital customer relationships",
    videoLink: "https://www.example.com/video2.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/standard-quality-control-concept-m_23-2150041855.jpg?uid=R180763559&semt=ais_hybrid",
    price: "1600",
    rating: "4.5",
    caseAuthorDesignation: "Digital Brand Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Digital Branding",
    publicationDate: "15-Apr-2024",
    caseLocation: "United Kingdom",
    language: "English",
    tags: ["Brand Loyalty", "Digital Marketing", "Community Building"],
    extract:
      "A direct-to-consumer brand implements innovative digital strategies to build customer loyalty. The case explores community engagement tactics and loyalty program effectiveness.",
    learningArea: "Digital Brand Management",
  },
  {
    id: 23,
    caseTopic: "Purpose-Driven Brand Building",
    lesson: "Creating authentic purpose-driven brand strategies",
    futureSkills:
      "Social impact assessment, sustainability marketing, stakeholder engagement",
    characters: "4",
    roles: [
      {
        role1: "Brand Strategist",
        role2: "Sustainability Officer",
        role3: "Communications Director",
        role4: "Social Impact Manager",
      },
    ],
    roleOfMainCharacter: "Brand Strategist",
    challenge: "Aligning brand purpose with business objectives",
    videoLink: "https://www.example.com/video3.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/front-view-man-with-wooden-blocks_23-2150196689.jpg?uid=R180763559&semt=ais_hybrid",
    price: "1700",
    rating: "4.8",
    caseAuthorDesignation: "Purpose-Driven Brand Consultant",
    subjectCategory: "Marketing",
    subjectSubCategory: "Brand Purpose",
    publicationDate: "10-Jul-2024",
    caseLocation: "Netherlands",
    language: "English",
    tags: ["Purpose-Driven Branding", "Sustainability", "Social Impact"],
    extract:
      "A consumer goods company repositions its brand around social and environmental purpose. The case examines the integration of purpose into brand strategy and its impact on business performance.",
    learningArea: "Purpose-Driven Strategy",
  },
  {
    id: 24,
    caseTopic: "Global Brand Localization",
    lesson: "Balancing global brand consistency with local relevance",
    futureSkills:
      "Global marketing, cultural adaptation, market entry strategy",
    characters: "3",
    roles: [
      {
        role1: "Global Brand Manager",
        role2: "Regional Marketing Head",
        role3: "Local Market Specialist",
      },
    ],
    roleOfMainCharacter: "Global Brand Manager",
    challenge: "Maintaining brand identity across diverse markets",
    videoLink: "https://www.example.com/video4.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/world-map-with-connected-icons_1232-177.jpg?uid=R180763559&semt=ais_hybrid https://img.freepik.com/free-photo/businessman-holding-tablet-with-virtual-application_1112-775.jpg?uid=R180763559&semt=ais_hybrid",
    price: "1900",
    rating: "4.6",
    caseAuthorDesignation: "Global Brand Director",
    subjectCategory: "Marketing",
    subjectSubCategory: "Global Branding",
    publicationDate: "05-Oct-2024",
    caseLocation: "Global",
    language: "English",
    tags: ["Global Branding", "Market Entry", "Cultural Adaptation"],
    extract:
      "A multinational brand expands into new markets while maintaining its core identity. The case explores strategies for successful brand localization and cultural adaptation.",
    learningArea: "Global Brand Strategy",
  },
  {
    id: 25,
    caseTopic: "Personal Branding Impact",
    lesson: "Leveraging personal brands for corporate success",
    futureSkills:
      "Personal branding, leadership communication, social media strategy",
    characters: "3",
    roles: [
      {
        role1: "CEO",
        role2: "Communications Director",
        role3: "Personal Brand Strategist",
      },
    ],
    roleOfMainCharacter: "CEO",
    challenge: "Aligning personal and corporate brand values",
    videoLink: "https://www.example.com/video5.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-progress-report-graph-concept_53876-15869.jpg?uid=R180763559&semt=ais_hybrid",
    price: "2000",
    rating: "4.9",
    caseAuthorDesignation: "Executive Brand Consultant",
    subjectCategory: "Marketing",
    subjectSubCategory: "Personal Branding",
    publicationDate: "15-Jan-2025",
    caseLocation: "United States",
    language: "English",
    tags: ["Personal Branding", "Leadership", "Corporate Brand"],
    extract:
      "A tech company's success is significantly influenced by its CEO's personal brand. The case examines the relationship between personal and corporate branding in modern business.",
    learningArea: "Personal Brand Strategy",
  },
  {
    id: 26,
    caseTopic: "Supply Chain Resilience",
    lesson: "Building resilient supply chains in face of global disruptions",
    futureSkills:
      "Risk management, supply chain optimization, contingency planning",
    characters: "4",
    roles: [
      {
        role1: "Supply Chain Director",
        role2: "Risk Manager",
        role3: "Logistics Manager",
        role4: "Procurement Specialist",
      },
    ],
    roleOfMainCharacter: "Supply Chain Director",
    challenge: "Maintaining operations during global supply chain disruptions",
    videoLink: "https://www.example.com/video1.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/still-life-supply-chain-representation_23-2149827286.jpg?uid=R180763559&semt=ais_hybrid",
    price: "1700",
    price: "2100",
    rating: "4.8",
    caseAuthorDesignation: "Global Supply Chain Director",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Supply Chain Management",
    publicationDate: "20-Jan-2024",
    caseLocation: "Global",
    language: "English",
    tags: ["Supply Chain", "Risk Management", "Global Operations"],
    extract:
      "A multinational manufacturer faces severe supply chain disruptions due to global events. The case examines strategies for building resilient supply networks and managing crisis situations.",
    learningArea: "Supply Chain Resilience",
  },
  {
    id: 27,
    caseTopic: "AI in Operations Management",
    lesson: "Implementing AI solutions for operational efficiency",
    futureSkills: "AI implementation, process automation, data analytics",
    characters: "3",
    roles: [
      {
        role1: "Operations Director",
        role2: "AI Implementation Lead",
        role3: "Process Engineer",
      },
    ],
    roleOfMainCharacter: "Operations Director",
    challenge: "Integrating AI solutions into existing operations",
    videoLink: "https://www.example.com/video2.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/human-use-computer-control-robot-arms-working-procuction-convoyed-smart-factory-industry-4_1150-43048.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1900",
    rating: "4.7",
    caseAuthorDesignation: "Operations Technology Director",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Technology Integration",
    publicationDate: "15-Apr-2024",
    caseLocation: "United States",
    language: "English",
    tags: ["AI Operations", "Automation", "Process Optimization"],
    extract:
      "A manufacturing company implements AI and automation solutions to improve operational efficiency. The case explores the challenges and benefits of integrating advanced technologies in traditional operations.",
    learningArea: "Operations Technology",
  },
  {
    id: 28,
    caseTopic: "Lean and Agile Operations",
    lesson: "Optimizing operations through lean and agile methodologies",
    futureSkills: "Lean management, agile operations, continuous improvement",
    characters: "3",
    roles: [
      {
        role1: "Operations Manager",
        role2: "Lean Specialist",
        role3: "Quality Engineer",
      },
    ],
    roleOfMainCharacter: "Operations Manager",
    challenge: "Implementing lean principles in complex operations",
    videoLink: "https://www.example.com/video3.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/still-life-business-roles-with-various-mechanism-pieces_23-2149352652.jpg?uid=R180763559&semt=ais_hybrid",
    price: "1800",
    rating: "4.6",
    caseAuthorDesignation: "Lean Operations Consultant",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Lean Management",
    publicationDate: "10-Jul-2024",
    caseLocation: "Japan",
    language: "English",
    tags: ["Lean Operations", "Agile Manufacturing", "Process Improvement"],
    extract:
      "A manufacturing facility implements lean and agile methodologies to improve efficiency. The case examines the transformation process and its impact on productivity and waste reduction.",
    learningArea: "Lean Management",
  },
  {
    id: 29,
    caseTopic: "Sustainable Operations Management",
    lesson: "Implementing environmentally responsible operations",
    futureSkills:
      "Sustainable operations, environmental management, circular economy",
    characters: "4",
    roles: [
      {
        role1: "Sustainability Director",
        role2: "Operations Manager",
        role3: "Environmental Specialist",
        role4: "Supply Chain Manager",
      },
    ],
    roleOfMainCharacter: "Sustainability Director",
    challenge: "Balancing sustainability with operational efficiency",
    videoLink: "https://www.example.com/video4.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/happy-businesswoman-giving-presentation-natural-resources-his-colleagues_23-2147826560.jpg?t=st=1740139286~exp=1740142886~hmac=6fa9563b80ab4741218a29c50743551059359ccfcb343d14ec09688a1944219d&w=1380",
    price: "2000",
    rating: "4.9",
    caseAuthorDesignation: "Sustainability Operations Director",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Sustainable Operations",
    publicationDate: "05-Oct-2024",
    caseLocation: "Germany",
    language: "English",
    tags: ["Sustainability", "Green Operations", "Environmental Management"],
    extract:
      "A manufacturing company transforms its operations to achieve sustainability goals. The case explores the implementation of eco-friendly practices and their impact on business performance.",
    learningArea: "Sustainable Operations",
  },
  {
    id: 30,
    caseTopic: "Remote Workforce Operations",
    lesson: "Managing distributed teams effectively",
    futureSkills:
      "Remote management, digital collaboration, workforce optimization",
    characters: "3",
    roles: [
      {
        role1: "Operations Director",
        role2: "HR Manager",
        role3: "Technology Lead",
      },
    ],
    roleOfMainCharacter: "Operations Director",
    challenge: "Maintaining productivity with remote teams",
    videoLink: "https://www.example.com/video5.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/man-online-video-conference-group-communication-via-computer-screen-guy-sitting-home-chair-listening-meeting-webinar-remote-working-learning_575670-956.jpg?t=st=1740138229~exp=1740141829~hmac=423960d72ae50d75b0cdfd3a0b797fccc7308b6d926c1dcb0883336937dcd2cc&w=1380",
    price: "1700",
    rating: "4.5",
    caseAuthorDesignation: "Remote Operations Director",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Workforce Management",
    publicationDate: "15-Jan-2025",
    caseLocation: "Global",
    language: "English",
    tags: ["Remote Work", "Digital Operations", "Workforce Management"],
    extract:
      "A global company transitions to a hybrid workforce model. The case examines strategies for maintaining operational efficiency with distributed teams.",
    learningArea: "Remote Operations",
  },
  {
    id: 31,
    caseTopic: "Supply Chain Resilience",
    lesson: "Adapting supply chains to global disruptions",
    futureSkills:
      "Risk management, supply chain optimization, contingency planning",
    characters: "4",
    roles: [
      {
        role1: "Supply Chain Director",
        role2: "Risk Manager",
        role3: "Logistics Coordinator",
        role4: "Procurement Manager",
      },
    ],
    roleOfMainCharacter: "Supply Chain Director",
    challenge: "Building resilient supply networks amid global disruptions",
    videoLink: "https://www.example.com/video1.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/still-life-supply-chain-representation_23-2149827322.jpg?t=st=1740139459~exp=1740143059~hmac=8b3a8581cf48a0dd2835c7cf0605ef5d7cd96ea26f1dd800507d729489189e33&w=1380",
    price: "2200",
    rating: "4.8",
    caseAuthorDesignation: "Global Supply Chain Director",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Supply Chain Management",
    publicationDate: "01-Feb-2024",
    caseLocation: "Global",
    language: "English",
    tags: ["Supply Chain", "Risk Management", "Global Operations"],
    extract:
      "A multinational manufacturer navigates severe supply chain disruptions caused by global events, implementing innovative solutions for resilience.",
    learningArea: "Supply Chain Resilience",
  },
  {
    id: 32,
    caseTopic: "AI in Operations",
    lesson: "Leveraging AI for operational excellence",
    futureSkills: "AI implementation, process automation, data analytics",
    characters: "3",
    roles: [
      {
        role1: "Operations Director",
        role2: "AI Implementation Lead",
        role3: "Process Engineer",
      },
    ],
    roleOfMainCharacter: "Operations Director",
    challenge: "Integrating AI solutions into manufacturing processes",
    videoLink: "https://www.example.com/video2.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/businessman-working-futuristic-office_23-2151003701.jpg?t=st=1740143085~exp=1740146685~hmac=b61c7ac9e43bf36f9a4ab737701fd9478b44c2a88d5682881755360cf949f142&w=1380",
    price: "2100",
    rating: "4.7",
    caseAuthorDesignation: "Operations Technology Director",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Technology Integration",
    publicationDate: "15-Apr-2024",
    caseLocation: "United States",
    language: "English",
    tags: ["AI Operations", "Automation", "Process Optimization"],
    extract:
      "A manufacturing company implements AI and robotics to transform its operations, achieving significant efficiency improvements and cost reductions.",
    learningArea: "Operations Technology",
  },
  {
    id: 33,
    caseTopic: "Lean Operations Implementation",
    lesson: "Implementing lean methodologies for operational excellence",
    futureSkills: "Lean management, process optimization, waste reduction",
    characters: "3",
    roles: [
      {
        role1: "Operations Manager",
        role2: "Lean Specialist",
        role3: "Quality Engineer",
      },
    ],
    roleOfMainCharacter: "Operations Manager",
    challenge: "Transforming traditional operations to lean methodology",
    videoLink: "https://www.example.com/video3.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-presentation_53876-167156.jpg?t=st=1740143318~exp=1740146918~hmac=58712e13c91b957243a0761aba7f021260fbd6226414788f484a84422492f539&w=1380",
    price: "1900",
    rating: "4.6",
    caseAuthorDesignation: "Lean Operations Consultant",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Lean Management",
    publicationDate: "01-Jul-2024",
    caseLocation: "Japan",
    language: "English",
    tags: ["Lean Operations", "Process Improvement", "Waste Reduction"],
    extract:
      "A manufacturing facility implements lean principles to optimize operations, resulting in significant waste reduction and efficiency gains.",
    learningArea: "Lean Management",
  },
  {
    id: 34,
    caseTopic: "Sustainable Operations",
    lesson: "Implementing environmentally responsible operations",
    futureSkills:
      "Sustainable operations, environmental management, green supply chain",
    characters: "4",
    roles: [
      {
        role1: "Sustainability Director",
        role2: "Operations Manager",
        role3: "Environmental Specialist",
        role4: "Supply Chain Manager",
      },
    ],
    roleOfMainCharacter: "Sustainability Director",
    challenge: "Balancing sustainability with operational efficiency",
    videoLink: "https://www.example.com/video4.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/researchers-looking-alternative-energy-souces_23-2149311461.jpg?t=st=1740143407~exp=1740147007~hmac=db228c0871cb32f52e9dc721ddd298007e0a35f0c7f03bed15c8d5699ed6d5a3&w=1800",
    price: "2000",
    rating: "4.9",
    caseAuthorDesignation: "Sustainability Operations Director",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Sustainable Operations",
    publicationDate: "15-Oct-2024",
    caseLocation: "Germany",
    language: "English",
    tags: ["Sustainability", "Green Operations", "Environmental Management"],
    extract:
      "A manufacturing company transforms its operations to achieve sustainability goals while maintaining profitability and operational efficiency.",
    learningArea: "Sustainable Operations",
  },
  {
    id: 35,
    caseTopic: "Remote Workforce Management",
    lesson: "Managing distributed teams effectively",
    futureSkills:
      "Remote management, digital collaboration, workforce optimization",
    characters: "3",
    roles: [
      {
        role1: "Operations Director",
        role2: "HR Manager",
        role3: "Technology Lead",
      },
    ],
    roleOfMainCharacter: "Operations Director",
    challenge: "Maintaining productivity with remote teams",
    videoLink: "https://www.example.com/video5.mp4",
    imageLink:
      "https://img.freepik.com/free-vector/communication-flat-icon_1262-18771.jpg?t=st=1740143482~exp=1740147082~hmac=e472c3c7ef24cfd245c5272b1f686af8d708be00a56a6563699ca1051e05dfbb&w=1380",
    price: "1800",
    rating: "4.5",
    caseAuthorDesignation: "Remote Operations Director",
    subjectCategory: "Operations Management",
    subjectSubCategory: "Workforce Management",
    publicationDate: "01-Jan-2025",
    caseLocation: "Global",
    language: "English",
    tags: ["Remote Work", "Digital Operations", "Workforce Management"],
    extract:
      "A global company successfully transitions to a hybrid workforce model while maintaining operational efficiency and team productivity.",
    learningArea: "Remote Operations",
  },
  {
    id: 36,
    caseTopic: "Future of Work Management",
    lesson: "Leading remote and hybrid teams effectively",
    futureSkills:
      "Remote leadership, digital collaboration, flexible work policy development",
    characters: "3",
    roles: [
      {
        role1: "Chief People Officer",
        role2: "Remote Work Director",
        role3: "Digital Workplace Manager",
      },
    ],
    roleOfMainCharacter: "Chief People Officer",
    challenge:
      "Maintaining company culture and productivity with distributed teams",
    videoLink: "https://www.example.com/future-work.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-having-meeting-modern-office_23-2149346464.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1650",
    rating: "4.8",
    caseAuthorDesignation: "Future of Work Strategist",
    subjectCategory: "Human Resources",
    subjectSubCategory: "Workforce Management",
    publicationDate: "10-Feb-2024",
    caseLocation: "Global",
    language: "English",
    tags: ["Remote Work", "Hybrid Teams", "Digital Workplace", "Flexible Work"],
    extract:
      "A global technology company redesigns its work policies and leadership approach to support a permanent hybrid workforce model, resulting in improved employee satisfaction and expanded talent acquisition capabilities.",
    learningArea: "Future of Work",
  },
  {
    id: 37,
    caseTopic: "DEI Strategy Implementation",
    lesson: "Building inclusive talent acquisition and retention programs",
    futureSkills:
      "Inclusive leadership, bias mitigation, diversity metrics analysis",
    characters: "3",
    roles: [
      {
        role1: "Chief Diversity Officer",
        role2: "Talent Acquisition Director",
        role3: "Employee Resource Group Lead",
      },
    ],
    roleOfMainCharacter: "Chief Diversity Officer",
    challenge:
      "Creating meaningful DEI initiatives that drive measurable results",
    videoLink: "https://www.example.com/dei-strategy.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/diverse-businesspeople-having-meeting_53876-103954.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1750",
    rating: "4.9",
    caseAuthorDesignation: "DEI Strategy Consultant",
    subjectCategory: "Human Resources",
    subjectSubCategory: "Diversity & Inclusion",
    publicationDate: "15-Apr-2024",
    caseLocation: "United States",
    language: "English",
    tags: ["Diversity", "Equity", "Inclusion", "Talent Acquisition"],
    extract:
      "A financial services firm implements a comprehensive DEI strategy that transforms hiring practices, creates inclusive leadership development, and establishes employee resource groups, resulting in significant improvements in workforce diversity and retention.",
    learningArea: "DEI Strategy",
  },
  {
    id: 38,
    caseTopic: "HR Analytics Implementation",
    lesson: "Leveraging data for employee performance and engagement",
    futureSkills: "People analytics, data visualization, evidence-based HR",
    characters: "3",
    roles: [
      {
        role1: "HR Analytics Director",
        role2: "HRIS Manager",
        role3: "Talent Management Lead",
      },
    ],
    roleOfMainCharacter: "HR Analytics Director",
    challenge:
      "Transforming HR from intuition-based to data-driven decision making",
    videoLink: "https://www.example.com/hr-analytics.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-discussing-charts-graphs-showing-results-their-successful-teamwork_1150-5183.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1900",
    rating: "4.7",
    caseAuthorDesignation: "People Analytics Expert",
    subjectCategory: "Human Resources",
    subjectSubCategory: "HR Analytics",
    publicationDate: "22-Jul-2024",
    caseLocation: "Singapore",
    language: "English",
    tags: [
      "People Analytics",
      "HR Metrics",
      "Data-Driven HR",
      "Employee Performance",
    ],
    extract:
      "A multinational corporation builds a sophisticated HR analytics function that identifies key drivers of employee engagement and performance, enabling targeted interventions that reduce turnover by 25% and improve productivity metrics.",
    learningArea: "HR Analytics",
  },
  {
    id: 39,
    caseTopic: "Workplace Wellbeing Strategy",
    lesson: "Implementing effective mental health and wellness initiatives",
    futureSkills:
      "Wellbeing program design, mental health awareness, culture development",
    characters: "3",
    roles: [
      {
        role1: "Employee Wellbeing Director",
        role2: "Mental Health Program Manager",
        role3: "Chief Medical Officer",
      },
    ],
    roleOfMainCharacter: "Employee Wellbeing Director",
    challenge:
      "Addressing burnout and stress in high-pressure work environments",
    videoLink: "https://www.example.com/wellbeing.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/group-diverse-people-having-business-meeting_53876-25060.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1550",
    rating: "4.8",
    caseAuthorDesignation: "Workplace Wellbeing Strategist",
    subjectCategory: "Human Resources",
    subjectSubCategory: "Employee Wellbeing",
    publicationDate: "05-Sep-2024",
    caseLocation: "Australia",
    language: "English",
    tags: [
      "Mental Health",
      "Wellbeing",
      "Employee Support",
      "Workplace Culture",
    ],
    extract:
      "A consulting firm implements a comprehensive wellbeing strategy to address burnout and mental health challenges, resulting in reduced absenteeism, improved retention rates, and enhanced employee satisfaction scores.",
    learningArea: "Workplace Wellbeing",
  },
  {
    id: 40,
    caseTopic: "AI in HR Transformation",
    lesson: "Balancing automation with human-centric HR practices",
    futureSkills:
      "HR technology implementation, AI ethics, digital HR strategy",
    characters: "3",
    roles: [
      {
        role1: "HR Technology Director",
        role2: "AI Implementation Lead",
        role3: "Employee Experience Manager",
      },
    ],
    roleOfMainCharacter: "HR Technology Director",
    challenge:
      "Implementing AI solutions while maintaining the human touch in HR",
    videoLink: "https://www.example.com/ai-hr.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/ai-technology-brain-background-digital-transformation-concept_53876-124669.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "2000",
    rating: "4.6",
    caseAuthorDesignation: "HR Technology Strategist",
    subjectCategory: "Human Resources",
    subjectSubCategory: "HR Technology",
    publicationDate: "18-Dec-2024",
    caseLocation: "United Kingdom",
    language: "English",
    tags: ["AI in HR", "HR Automation", "Recruitment Technology", "Digital HR"],
    extract:
      "A global retail organization implements AI-powered solutions across its HR function, from candidate screening to performance management, while developing a framework to ensure ethical use of technology and maintain human connection.",
    learningArea: "HR Technology",
  },
  {
    id: 41,
    caseTopic: "FinTech Disruption Analysis",
    lesson: "How digital innovation is reshaping financial services",
    futureSkills:
      "Digital banking strategy, blockchain implementation, fintech partnership models",
    characters: "3",
    roles: [
      {
        role1: "Digital Banking Director",
        role2: "Fintech Partnership Lead",
        role3: "Customer Experience Manager",
      },
    ],
    roleOfMainCharacter: "Digital Banking Director",
    challenge:
      "Competing with agile fintech startups while managing legacy systems",
    videoLink: "https://www.example.com/fintech-disruption.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/businessman-using-tablet-analyzing-sales-data-economic-growth-graph-chart_1421-90.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1850",
    rating: "4.8",
    caseAuthorDesignation: "Fintech Strategy Consultant",
    subjectCategory: "Finance",
    subjectSubCategory: "Financial Technology",
    publicationDate: "05-Mar-2024",
    caseLocation: "United Kingdom",
    language: "English",
    tags: ["Fintech", "Digital Banking", "Blockchain", "Payment Innovation"],
    extract:
      "A traditional banking institution responds to fintech disruption by launching its own digital wallet, implementing blockchain for cross-border payments, and creating an open banking platform that enables third-party innovation.",
    learningArea: "Fintech Strategy",
  },
  {
    id: 42,
    caseTopic: "Financial Risk Management",
    lesson: "Navigating uncertainty in volatile economic environments",
    futureSkills: "Risk modeling, hedging strategies, scenario planning",
    characters: "3",
    roles: [
      {
        role1: "Chief Risk Officer",
        role2: "Treasury Manager",
        role3: "Financial Planning Director",
      },
    ],
    roleOfMainCharacter: "Chief Risk Officer",
    challenge:
      "Developing effective risk mitigation strategies during high inflation and market volatility",
    videoLink: "https://www.example.com/risk-management.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-discussing-charts-graphs-showing-results-their-successful-teamwork_1150-5183.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "2100",
    rating: "4.7",
    caseAuthorDesignation: "Financial Risk Management Expert",
    subjectCategory: "Finance",
    subjectSubCategory: "Risk Management",
    publicationDate: "18-May-2024",
    caseLocation: "United States",
    language: "English",
    tags: [
      "Risk Management",
      "Inflation Hedging",
      "Market Volatility",
      "Financial Planning",
    ],
    extract:
      "A multinational corporation implements a comprehensive risk management framework to navigate economic uncertainty, including sophisticated hedging strategies, diversified supply chain financing, and stress-testing scenarios.",
    learningArea: "Financial Risk Strategy",
  },
  {
    id: 43,
    caseTopic: "ESG Finance Integration",
    lesson: "Embedding sustainability into financial decision-making",
    futureSkills: "ESG analysis, sustainable investment, impact measurement",
    characters: "3",
    roles: [
      {
        role1: "Chief Sustainability Officer",
        role2: "ESG Investment Director",
        role3: "Financial Reporting Manager",
      },
    ],
    roleOfMainCharacter: "Chief Sustainability Officer",
    challenge:
      "Balancing financial returns with environmental and social impact",
    videoLink: "https://www.example.com/esg-finance.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-discussing-sustainable-development-goals_53876-138415.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1950",
    rating: "4.9",
    caseAuthorDesignation: "Sustainable Finance Strategist",
    subjectCategory: "Finance",
    subjectSubCategory: "Sustainable Finance",
    publicationDate: "12-Jul-2024",
    caseLocation: "European Union",
    language: "English",
    tags: [
      "ESG",
      "Sustainable Investing",
      "Impact Finance",
      "Corporate Responsibility",
    ],
    extract:
      "An investment management firm develops a comprehensive ESG integration framework that transforms its investment process, corporate engagement strategy, and reporting practices while maintaining strong financial performance.",
    learningArea: "Sustainable Finance",
  },
  {
    id: 44,
    caseTopic: "Cash Flow Optimization",
    lesson: "Strengthening financial health through liquidity management",
    futureSkills:
      "Working capital optimization, cash forecasting, liquidity planning",
    characters: "3",
    roles: [
      {
        role1: "Chief Financial Officer",
        role2: "Treasury Operations Director",
        role3: "Accounts Receivable Manager",
      },
    ],
    roleOfMainCharacter: "Chief Financial Officer",
    challenge:
      "Improving cash conversion cycle during supply chain disruptions",
    videoLink: "https://www.example.com/cash-flow.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-discussing-financial-charts-office_23-2149060089.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1750",
    rating: "4.6",
    caseAuthorDesignation: "Treasury Management Consultant",
    subjectCategory: "Finance",
    subjectSubCategory: "Cash Management",
    publicationDate: "28-Sep-2024",
    caseLocation: "Global",
    language: "English",
    tags: [
      "Cash Flow",
      "Working Capital",
      "Liquidity Management",
      "Financial Health",
    ],
    extract:
      "A mid-sized manufacturing company implements a comprehensive cash flow optimization strategy, reducing its cash conversion cycle by 15 days and establishing robust forecasting methods that enable strategic growth despite market uncertainties.",
    learningArea: "Cash Management",
  },
  {
    id: 45,
    caseTopic: "AI in Financial Operations",
    lesson: "Leveraging machine learning for forecasting and fraud prevention",
    futureSkills:
      "AI implementation, predictive analytics, fraud detection systems",
    characters: "3",
    roles: [
      {
        role1: "Financial Technology Director",
        role2: "Data Science Lead",
        role3: "Financial Controls Manager",
      },
    ],
    roleOfMainCharacter: "Financial Technology Director",
    challenge: "Implementing AI solutions while ensuring regulatory compliance",
    videoLink: "https://www.example.com/ai-finance.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/ai-technology-microchip-background-digital-transformation-concept_53876-124669.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "2200",
    rating: "4.8",
    caseAuthorDesignation: "Financial AI Implementation Expert",
    subjectCategory: "Finance",
    subjectSubCategory: "Financial Technology",
    publicationDate: "15-Nov-2024",
    caseLocation: "Singapore",
    language: "English",
    tags: [
      "AI in Finance",
      "Fraud Detection",
      "Predictive Analytics",
      "Financial Forecasting",
    ],
    extract:
      "A global financial services firm deploys machine learning algorithms that improve forecasting accuracy by 35% and develop an advanced fraud detection system that identifies suspicious patterns in real-time, reducing fraud losses by 60%.",
    learningArea: "Financial Technology",
  },
  {
    id: 46,
    caseTopic: "E-commerce Returns Optimization",
    lesson: "Streamlining reverse logistics in online retail operations",
    futureSkills:
      "Supply chain optimization, e-commerce operations, cost analysis",
    characters: "3",
    roles: [
      {
        role1: "SVP of Supply Chain",
        role2: "President of Supply Chain",
        role3: "Returns Process Consultant",
      },
    ],
    roleOfMainCharacter: "SVP of Supply Chain",
    challenge: "Reducing costs associated with e-commerce product returns",
    videoLink: "https://www.example.com/returns-optimization.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/warehouse-worker-scanning-package-barcode_53876-94188.jpg?t=st=1740137202~exp=1740140802~hmac=310e36a1f4c30a3103bdc979ead5376b96c2bd6427c95c3c4a561039cf2805e2&w=1380",
    price: "1950",
    rating: "4.8",
    caseAuthorDesignation: "Senior IT Consultant",
    subjectCategory: "Operations",
    subjectSubCategory: "E-commerce Operations",
    publicationDate: "10-Jun-2024",
    caseLocation: "United States",
    language: "English",
    tags: [
      "E-commerce Returns",
      "Reverse Logistics",
      "Cost Optimization",
      "Supply Chain",
    ],
    extract:
      "Electrosphere, a traditional electronics retailer that successfully transitioned to e-commerce, faces significant costs from product returns. The case explores how the company analyzes its returns process and implements both short-term and mid-term solutions to optimize operations while maintaining customer satisfaction.",
    learningArea: "E-commerce Operations",
  },
  {
    id: 47,
    caseTopic: "Competing Against A Product Leader",
    lesson: "Strategies for challenging established market leaders",
    futureSkills: "Competitive analysis, market positioning, brand strategy",
    characters: "3",
    roles: [
      {
        role1: "Marketing Director",
        role2: "Product Manager",
        role3: "Sales Head",
      },
    ],
    roleOfMainCharacter: "Marketing Director",
    challenge:
      "Revitalizing growth for an underperforming brand in a competitive category",
    videoLink: "https://www.example.com/competing-leader.mp4",
    imageLink:
      "https://img.freepik.com/free-photo/business-people-office-meeting_23-2148908942.jpg?t=st=1740139751~exp=1740143351~hmac=a2d5e8f9b9e3d5f6a7b8c9d0e1f2a3b4c5d6e7f8a9b0c1d2e3f4a5b6c7d8e9f0",
    price: "1750",
    rating: "4.6",
    caseAuthorDesignation: "Business Head",
    subjectCategory: "Marketing",
    subjectSubCategory: "Competitive Strategy",
    publicationDate: "25-Dec-24",
    caseLocation: "India",
    language: "English",
    tags: ["Marketing", "Manufacturing", "Sales & Distribution Network"],
    extract:
      "A leading manufacturing company which has a several brands is lagging behind it's competition in one of the categories. The business has been struggling with poor growth and needs to take a call on the way forward",
    learningArea: "Competitive Strategy",
  },
];
